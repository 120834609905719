
//  utility classes
@import 'variables';

@import 'https://unpkg.com/aos@next/dist/aos.css';


//  used varaibles
h2{
    font-size: 2rem;
        @include phone-up{
                font-size: 1.5rem!important;
            }
}
.pt-3.pb-3{
    @include phone-up{
        padding: 0px!important;
        margin-bottom: 10px!important;
    }
}
.img-fluid.px-5.pt-4{
    @include phone-up{
        padding-top: 0px!important;
    }
}
.pt-3.pr-4.text-small-center{
    @include phone-up{
        padding: 0px!important;
        margin: 0px!important;
    }
}
h4{
    font-size: 1.5rem;
    @include phone-up{
        font-size: 1.4rem;
    }
}
h2.pt-5.pb-3{
    @include phone-up{
    padding: 20px!important;
    margin: 0px!important;}
}
.display-4{
    @include phone-up{
        margin-bottom: 0px;
       
        span{
            font-weight: 400!important;
        }
    }
}

body{
    font-size: $baseFontSize;
    overflow-x: hidden;
    font-family: $font-family-base;
    padding-right: 0!important;
    // @include media-breakpoint-down(sm) {
    //     padding-top: 0;
    // }

    // padding-top: 80px;
    @include phone-up{
        font-size: 0.8rem!important;
    }
}

.modal-open {
    overflow: hidden!important;
}

button.close{
    position: absolute!important;
    right: 0!important;
    top: 0!important;
    z-index: 20;
}
.form-control{
    min-height: 58px;
}
.navbar-collapse{
    @include phone{background: #F9FBFE!important;
        margin-left: -30px;
        margin-right: -30px;
        padding: 30px!important;
    }
    @include tablet-down{background: #F9FBFE!important;
        margin-left: -30px;
        margin-right: -30px;
        padding: 30px!important;
    }
    @include desktop-down{background: #F9FBFE!important;
        margin-left: -30px;
        margin-right: -30px;
        padding: 30px!important;
    }
}

a:hover{
    text-decoration: none;
}

.row:before, .row:after {display: none !important;}
//  for body-scroll

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 2px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
    border-radius: 2px;
	background-color: $primary;

}

//  for mnodal

.modal-open::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.modal-open::-webkit-scrollbar
{
	width: 0px;
	background-color: #F5F5F5;
}

.modal-open::-webkit-scrollbar-thumb
{
    border-radius: 2px;
	background-color: $primary;

}
select{
    -webkit-appearance: none;
    appearance: none;
    background-image: url('../../assets/img/down.svg')!important;
    background-position: right  center; 
    background-repeat: no-repeat;
}
footer select{
    -webkit-appearance: none;
    appearance: none;
    background-image: url('../../assets/img/arrow-down-wt.svg')!important;
    background-position: right  center; 
    background-repeat: no-repeat;
}


.MuiFilledInput-input {
    padding: 27px 15px 10px;
}

#wraper
{max-width: 100%;display: block;overflow: hidden;}
.hidden-overflow{overflow: hidden;}
input[type][disabled]
{background-color: #f9f9f9;color: #ddd;cursor: default;}
input[type][disabled] + label {color: #999;cursor: default;}
$danger: #FA3B18;
$info: #75ACFA;
$warning: #FFBE68;
$success: #81A53D;
$light: #F9FBFE;
$dark: #000000;
$secondary:#2B3344;
$white : #fff;
$footer-bg:#1F1F1F;
// slider image
$sliderImage : "../img/slider/bg.png";
$aboutImage : "../img/about-img.png";
$contactImage : "../img/contact-us.png";
$dropdown-img:"../img/dropdown.svg";
.font-light
{
font-weight: 100!important;
}
//img-overlay
.img-overlay
{
    position: relative;
    .img-hover{
    top: 25%;
    position: absolute;
    left: 0;
    max-width: 80%;
    @include phone-up{top: 5%; max-width: 60%;}
    }
}
.navbar-collapse{
    .btn{
        min-width: auto;
    }
}

.background-img{
    position: relative;
    background-position: center center;
    -webkit-background-position: center center;
    
    z-index: 0;
    background-attachment: fixed;
    -webkit-background-attachment: fixed;
    -o-background-attachment: fixed;
    -moz-background-attachment: fixed;
    background-size: 100%;
    
    @include media-breakpoint-down(sm) {
        background-attachment: scroll;
        background-size: cover;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-position: center center;
        -webkit-background-position: center center;
        -moz-background-position: center center;
    }
}
*, input, button, a{
    outline: none!important;
    transition: all ease-in-out .25s;
    &:focus
    {outline: none!important;box-shadow: 0px 0px 0px #D1E6FF !important;}
}


//  theme element like header body content section
//  for all section content
h2{
    font-size: $titleSection;
    @include phone-up{
        font-size: $titleSection - 10;
    }
    margin-bottom: $titleSection - 10px;
}

 .MuiFilledInput-input {
    padding: 27px 4% 10px!important;
}
// button custome section for styling

.btn.btn-outline-primary{
&.btn-primary{
  box-shadow: 0 6px 10px lighten($primary , 30%);
}
   border-color: #CDE4FF!important;

}
.contentSection{
    padding:0px 0 0 0  !important;
    p{
        font-size: $baseFontSize;
        font-weight: 300;
        color: $gray-600;
        margin-bottom: $titleSection ;
        

    }
    li{ 
        font-size: $baseFontSize;
        font-weight: 400;
        color: $gray-600;
       

    }


}
.about-img{
    background-image:url($aboutImage);
}
.contact-img{
    background-image:url($contactImage);
}
.btn-primary{
    color: $white;
}
.btn-outline-primary:hover {
    color:  $white;
    background-color: $primary;
    border-color: $primary;
}
.overlay-patt{
    position: relative;
    &:before{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      background-image: url(../img/pattern.svg);
  }
}
.overlay{
      &:before{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;
        background-color: rgba(232,243,255, .85);
    }
}

.overlay-dark{
    &:before{
    
      background-color: rgba(0,0,0, 0.5) !important;
  }
}
//free-15-days
.free-15-days
{
    position: relative;
    box-shadow: inset 2px 2px 20px #ddd;
    background-image: url(../img/pattern.svg);
}
//register tab
.round-arrow-box
{
    border-bottom: none!important;
 li{
      position: relative;
      margin-right: 150px !important;
      @include phone-up
       {margin-right: 60px !important;}
       @include phone
       {margin-right: 55px !important;}
      &:after{
       content: url(../img/arrow-rt.svg);
       position: absolute;
       left: 112px;
       top: 43px;
       @include phone-up
       {max-width: 100%;
        left: 74px;
        top: 24px;
        content: url(../img/arrow-rt-mob.png);
        }
        @include phone
        {left: 60px;top: 18px;}
      }
      &:last-child
      {
        margin-right: 0px !important;
          &::after
      {
        left: 0px;
        content: "";
      }
    }
    .nav-link
    {color: $dark;
     font-size: $base-font * 3 - 8;
     font-family: $font-family-base;
     background-color: #DAEBFF;
     padding: 15px 35px;
     border-radius: 100%;
     border:  7px solid #DAEBFF;
     @include phone-up{
        font-size: 30px;
        padding: 5px 19px;
     }
     @include phone
     {
        padding: 0px 14px;
     }
     
      &.active, &:hover
      {
        border: 7px solid rgba(255, 255, 255, 0.4);
        background-color: #DAEBFF;
      }
    }
    p{
        position: absolute;
        font-size: $base-font!important;
        min-width: 130px;
        text-align: center;
        left: -10px;
        margin-top: 30px;
        line-height: 20px;
        font-family: $font-family-base;
        @include phone-up{
            font-size: 10px !important;
            line-height: 12px;
            min-width: 86px;
        }
    }
   }
}
.register-form
{
    background-color: $white;
    // background-image: linear-gradient(rgba(91, 154, 255, .07) 1%, $white 15%);
}
//Sub Nav
.sub-nav{font-size: $base-font - 4; font-weight: 600 !important; word-spacing: 5px; margin-bottom: 0px;}

//  carousel section

.carousel {
    background: #EEE;
  }
  
  .carousel-cell {
      min-width: 420px;
    @include media-breakpoint-down(sm) {

      min-width: 380px;    
     }

    
    margin-right: 10px;
   
    border-radius: 5px;
  
  }
  
  /* cell number */
  .carousel-cell:before {
    display: block;
    text-align: center;
    line-height: 200px;
    font-size: 80px;
  }
//  header section

.headeBar{
    .topBarText{
        @include media-breakpoint-down(sm) { 
            font-size: 14px;
        }
    }
}
.callBtn{
  
    .la{
        font-size: 22px;;
    }
    .btn-sm {
        width: 35px;
        height: 35px;
        padding: 0;
        line-height: 2.8;
        &:focus, &:hover{
            color: #fff!important;
        }
    }
}
//hero image with color
.hero-img
{   overflow-y: hidden;
    background-size: cover!important;
    padding-bottom: 0px!important;
    height: 100%;
    @include phone-up { margin-bottom: $base-font - 16; height: 100%; overflow: hidden;}
    .jumbotron
    {
         @include phone-up { margin-bottom: $base-font - 16; }
        &:before {background-color: rgba(255, 255, 255, 0.70) !important;}
        .hero-baanner{
        .hero-baanner-img
        {display: none;
         img{max-width: 800px; display: block; margin-left: auto; margin-right: auto; margin-top: 3%;}
         @include tablet-up { display: block; margin-top: 15px;}
         @include desktop {margin-top: 15px;}
         iframe{
          padding: 2px;
          background: #333;
          border-radius: 2px;
          // z-index: -1;
          position: relative;
         }
         .iframehover{
           background-color: rgba(0,0,0,.0);
           width: 100%;
           position: absolute;
           top: 0px;
           z-index: 99999999;
           height: 100%;
         }
        }
        .hero-baanner-img-mob
        {
         display: block;
         img{max-width: 85%; display: block; margin-left: auto; margin-right: auto;
            @include phone-up {margin-top: 35%;}
            @include phone {margin-top: -5%;}
            @include tablet {margin-top: 24%;}
           }
            @include tablet-up {display: none;}
            @media (max-width: 767px)
            {margin-top: 20px;}
            iframe{
              padding: 4px;
              background: #333;
              border-radius: 2px;
              // z-index: -1;
              position: relative;
             }
             .iframehover{
               background-color: rgba(0,0,0,.0);
               width: 100%;
               position: absolute;
               top: 0px;
               z-index: 99999999;
               height: 100%;
             }
        }
       }
        h1{font-size: 50px; margin-top: 10vh;
          
            span{font-weight: 200;}
            @include media-breakpoint-down(sm){
                font-size: 1.5rem ;
            }
            @include phone-up {font-size: 1.5rem; margin-top: 10vh; }
            @media (max-width: 767px)
          {
          margin-top: 15vh;
          }
          }
        .lead{ margin-bottom: 50px; @include phone-up {font-size: $base-font !important;} }
    }
}
//services-img
.services-img
{
    h1{
        span{
            font-weight: 100;
        }
    }
}
//phone animate
.phone-animate
{
    position: relative;
    .iwatch
    {
       position: absolute;
       z-index: 5;
       bottom: -25px;
       right: 140px;
       width: 155px;
    }
    .android-galaxy
    {
       position: absolute;
       z-index: 3;
       top: 40px;
       width: 220px;
       right: 40px;
    }
    .iphone-x
    {
       position: relative;
       z-index: 1;
       width: 200px;
    }
    .mob-circle
    {
       position: absolute;
       z-index: 3;
       top: 40px;
       width: 290px;
       right: 40px;
       @include phone {width: 260px;}
    }
}
//modal shadow
.modal-dialog {
    box-shadow: 0px 20px 13px #7AAFED !important;
}
//details-bg
.details-bg
{
    background-size: contain !important;
    min-height: 300px;
    @include phone-up{min-height: auto;}
}
// jumbotron
//btn bg
.btn-radius 
{border-radius: 100px;}
.btn-bg-border
{
background-color: $primary;
border: 4px solid #C2DAF7;
padding: 10px 11px;
vertical-align: middle;
img{ margin-left: 1px;margin-top: -3px;}
}
.jumbotron{
    background-color: $dark;
    @extend .background-img;
    @extend .overlay;
//  top info Bar
    .infoBar{
        background: rgba(255,255,255,0.1);
    padding: 5px 10px;
    display: inline-block;
    color: #fff;
    border-radius: 4px;
    border: 1px solid rgba(255,255,255,0.1);
 
    @include media-breakpoint-down(sm) {
          font-size: 10px; 
     }
    font-weight: 400;
    }
  

//  for text styling
    h1{
        font-size:60px;
        font-weight: bold;
        @include media-breakpoint-down(sm) {
            font-size: 50px;
         }
    }

}
.jumbotron-dark
{
    @extend .overlay-dark;
}
//  card section 
.home-icon-box{
.card{
    border: 0px solid #E4ECFE;
    transition: all ease-in-out .25s;
    cursor: pointer;
    &:hover{
        box-shadow: 0px 2px 8px $gray-300;
    }
  
        .icon-bg{
            background: url(../img/bg-icon-box.png) no-repeat center center;
            margin-bottom: 20px;
        }
        h4{
            color: $secondary;
            font-size: $baseFontSize - 1px;
            margin-bottom: $base-font;
            font-family: $font-family-base;
        }
        p{
            color: $secondary;
            font-weight: 300;
            font-family: $font-family-base;
            line-height: $base-font + 4;
        }
        a{font-size: $base-font - 2;}
    
}
}

// bg-secondry
.bg-secondry{
    background-color: $shadow-border;
    padding: 4px 0em;
    overflow: hidden;
    .shadow
    {
        &:hover{box-shadow: 0px 5px 20px #B1CBEA!important;
            -webkit-transition: all 0.50s cubic-bezier(0.165, 0.84, 0.44, 1);
            -ms-transition: all 0.50s cubic-bezier(0.165, 0.84, 0.44, 1);
            transition: all 0.50s cubic-bezier(0.165, 0.84, 0.44, 1);
            -webkit-transform: scale(1.02, 1.02);
            transform: scale(1.02, 1.02);
            width: 100%;
            zoom:101%;
            -ms-zoom: 101%;
        }
    }
}
#nav-tabContent{
#monthly{min-height: 410px;}
}
//  about us section
//list image
.list-img, .list-img-2
{
   ul{ list-style: none; margin: 0px; padding: 0px;
        li{
    line-height: 20px;
    font-size: $base-font - 2;
    padding-left: 40px;
    letter-spacing: -0.5px;
    margin-bottom: 22px;
    position: relative;
    
    @include phone-up{
        margin-bottom: 10px;
        min-height: 30px;
    }
    &::before{content: url(../img/list-check-icon.svg); position: absolute;left: 0px; top: -2px;
        @include phone-up{top: 0px;
      
        }
    }
    }}}
.list-img-2
{ li{
    background-image: url(../img/list-green.svg) !important;
    padding-left: 24px!important;
    font-size: $baseFontSize;
    line-height: 18px !important;
    @include phone-up{
        margin-bottom: 10px;
    }
    }
}
.dev-scan-bg
{
    padding-left: 0px;
    background-size: 95%!important;
    img{
        margin-top:-40px;
        margin-bottom: -28px;
        text-align: right;
    }
}

@media (max-width: 575.98px) { 
    .hidden-xs{
      display: none!important;
    }
  }

  .pocket-info
  {
    h1{font-size: $base-font * 5; line-height: 82px; ;
        @include phone-up{    
            font-size: $base-font * 3 + 2; line-height: $base-font * 3 + 4;
         }
        span
        {font-weight: 200;}
      }
 }
 .pocket-info
  {
    h2{font-size: $base-font * 5; line-height: 82px; ;
        @include phone-up{    
            font-size: $base-font * 3 + 2; line-height: $base-font * 3 + 4;
         }
        span
        {font-weight: 200;}
      }
 }
 //bg primary gradient
 .bg-primary-gradient
 {
    background-color: red; 
    background-image: linear-gradient(-45deg, #002B77, #4A90E2 80%); 
    background-image: linear-gradient(-45deg, #002B77, #4A90E2 80%); 
 }
 h2.font-50{
     font-size: $base-font * 3 + 2;
     @include phone-up{    
        font-size: $base-font * 2 - 2;
     }
 }
//    cta section
///@include
.request-trial-form
{
.form-group
{
    label{margin-left: 0px; color: $dark; font-size: $base-font - 2;}
}
// .form-control
// {
 
    &:focus
    {
        background-color: #fff;
    }

.input-group-text
{
    border: 1px solid #e5e6e8 !important;
    background-color: #fff!important;
}
.input-group-append{
    height: 50px;
    margin-left: -2px;
}
}
//demo-section
.demo-section-top
{
 
 position: relative;
.jumbotron{
    margin-bottom:0px !important;
    min-height: 450px;
    @extend .overlay-dark;
    // &::before{background-color:rgba(255, 255, 255, 0.7);}
}}
.demo-sub-box
{
    background-color: $white;
    margin-bottom: -130px;
    .demo{
        width: 100%;
        position: relative; top: -250px;
        .demo-img-lt
        {
            overflow: hidden;
            max-height: 580px;
          img{ width: 100%; display: block; position: relative; top: -150px;}
        }
    }
    .request-trial-form {
        background-color: $white;
        box-shadow: -2px 2px 8px 0px rgba(200, 200, 200, .6);
    }
}
// login popup
.login-popup
{
.form-group
{
    label{margin-left: 5px; color: $dark;}
}
// .form-control
// {
//     padding:5px 15px;
//     height: 50px;

//     background-color: #F3F8FD;
//     border: 1px solid rgba(149, 190, 239, .58);
//     // &:focus
//     // {
//     //     background-color: #D1E6FF;
//     // }
// }
}
.info-input{
    position: absolute;
    right: 28px;
    top: 45px;
}
.select-product-demo-card
{
    table{ tr{td{padding:10px; min-height: 100px;}}}
    .radio-active
        {text-align: right;
    .form-radio
    {
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     display: inline-block;
     position: relative;
     background-color: #E2F0FF;
     color: $white;
     top: 0px;
     height:30px;
     width: 30px;
     border: 2px solid #D3E4F8;
     border-radius: 6px;
     cursor: pointer;     
     margin-right: 7px;
     outline: none;
     &:checked::before
     {
      position: absolute;
      font: 16px/1 'Open Sans', sans-serif;
      left: 9px;
      top: 3px;
      content: '\02143';
      transform: rotate(40deg);
     }
     &:hover
     {background-color: #f7f7f7;}
     &:checked
     {
      background-color: $primary;
     }
    }
    }
    .card{
      box-shadow: 0px 0px 3px 0px #DBDFE3!important;
      @include tablet-up{min-height: 100px;}
      &:hover{box-shadow: 0px 15px 10px 0px rgba(219, 223, 227, .5)!important;}
      img{max-height: 100px;@include phone-up{max-width: 80px}}
      h4{font-size: 16px;
          @include phone-up{font-size: $base-font;}}
      p{
          margin-bottom: 0px;
        @include phone-up
        {font-size: $base-font - 4;}
        b, span{ @include phone-up
            {font-size: $base-font - 2;}
        }
      }
     }
     .mg-target-open
     {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
     }
     .mg-row-open
     {
        .card
        {
            box-shadow: 0px 10px 15px 3px rgba(74, 144, 226, 0.20) !important;
        }
        .radio-active
        {
            background-color: $primary !important;
            border: 5px solid #D3E4F8!important;
            content: url(../img/checked.svg);
           
        }
     }
}
.mg-close
        {
          display: none!important;
        }
.demo-monthly-box-lt
{
    background: url(../img/car-demo.png) no-repeat top left;
}
.mg-target
{
   background-color: $primary-sdw;
   color: $dark !important;
}
.demo-monthly-box-rt 
{
    max-width: 650px;
    .white-text-box{
        background-color: $white;
        border: 1px solid rgba(149, 190, 239, 0.58);
        padding: 8px 15px;
        border-radius: 8px;
        p{
            margin-bottom: 0px;
            font-size: $base-font + 2;
            b, span {
                 font-size: $base-font * 2 - 2;
            };
        }
    }
}
//v-line
.v-line
{
    border-left: 1px solid $gray-200;
    padding-left: 15px !important
}
.edit-link
{
  a{color: $dark;
    text-decoration: none;}
}
.sub-text-1
{
font-size: $base-font - 4;
color: $gray-200;
}
//review-details
.review-details
{
    .card{
        .card-body
        {
            padding: 10px;
        }
     p{
         color: $dark;
         margin-bottom: 2px;
         small{
             color: $dark-gray;
         }
     }
    }
}
.error-input
{
    color: $danger;
    font-size: $base-font - 4;
    line-height: 14px;
    margin-bottom: 0px;
    margin-top: 8px;
    position: relative;
    right: 20px;
    left: 20px;
}
///////
.left-login-bg
{
    overflow: hidden;
    @media (min-width:1025px) { 
    max-height: 550px;
    }
img{ min-height: 549px; width: 100%; display: block;position: relative;}
}
.login-rt
{
    h1{
        font-family: $font-family-base;
        font-weight: 800;
        font-size: $base-font * 3 + 2;
        @include phone-up{ 
            font-size: $base-font * 2 - 3 ;
            margin-top: 30px;
        }
    }
    h2{
        font-family: $font-family-base;
        font-weight: 100;
        font-size: $base-font * 3 + 2;
        color: $primary;
        @include phone-up { 
            font-size: $base-font * 3 - 8;
        }
    }
    h3 {
        font-family: "Helvetica Neue";
        font-weight: 100;
        font-size: $base-font * 2 - 2;
        color: #4A90E2;
        @include phone-up { 
            font-size: $base-font * 3 - 8;
        }
    }
    p{
        font-family: $font-family-base;
        font-weight: 300;
        @include phone-up{ 
            font-size: $base-font!important;
            &.input-errors{font-size: 12px!important;}
        }
        
    }
 }
.cta{
  
    box-shadow:0px 2px 4px inset #999;
    -webkit-box-shadow:0px 2px 4px inset #999;
    -moz-box-shadow:0px 2px 4px inset #999;
    -o-box-shadow:0px 2px 4px inset #999;
}

//  footer section
footer{
    color:lighten( $dark, 90%);
    a{  color: lighten( $dark, 90%);
        &:hover{color: $gray-500;  text-decoration: none;}
    }
 .footerLinks{
     list-style: none;
     margin: 0;
     padding: 0;
     li{ margin: 10px 0;
          font-weight: 300;
          a{
              color: lighten( $dark, 90%);
              &:hover{color: $gray-500; text-decoration: none;}
          }
    }
 }
  
 .formFooter{
     label{
        color: lighten( $dark, 90%);
        top: -6px;
         
     }
  
   
     border-radius: 0;
     color: lighten( $dark, 90%);
     
     min-width: 200px;
     font-weight: lighter;
    
     border-color: lighten($dark, 30%);
    //  -webkit-appearance: none;
    //  -o-appearance: none;
    //  -moz-appearance: none;
    //  appearance: none;

  .MuiInputLabel-filled.MuiInputLabel-shrink{
    top: 6px;
    opacity: 0;
  }

  .makeStyles-root-7{
      border-radius: 0px!important;
      border-width:2px;
      border-color: #4d4d4d;
  }
  select.form-control{
      height: 50px;
      background-image: url('../../assets/img/down-white.svg')!important;
      background-repeat: no-repeat;
      background-position: right center;
  }
    .MuiInputBase-root{
        background-color: #1a1a1a;
        border-color: #4d4d4d!important;
    }
    .MuiInputBase-input, .form-control {
        background: transparent;
        border-radius: 4px;
        color: $white!important;
    //     &.MuiFilledInput-input {
    //     padding: 27px 15px 10px!important;
    // }
       
       width: 100%;

        height:30px;
        padding: 6px 10px!important;
    
        font-weight: 400;
        background-color: #1a1a1a;
        border-color: #4d4d4d!important;
        &.custom-select{
            &:focus, &:active{
                outline: none;
            }
            border-radius: 0;
            background-image: url($dropdown-img);
        background-position: 98% center;
        -moz-appearance: listbox;
        background-repeat: no-repeat;
        option{
            background-color: #fff;
            color: lighten( $dark, 30%);
        }
        }
        
        &::-webkit-input-placeholder{
            color: #ddd;

        }
    }
 



     
 }

//  common classes

.form-control {
    min-height: 50px;
    border-radius: 0;
    &.custom-select{
        &:focus, &:active{
            outline: none;
        }
        border-radius: 0;
        background-image: url($dropdown-img);
    background-position: 98% center;
    -moz-appearance: listbox;
    background-repeat: no-repeat;
   
    }
    
    &::-webkit-input-placeholder{
        color: $dark;

    }
}


//   border-color
.border-top, .dropdown-divider{
    border-color: lighten($black, 30%)!important;
}

.baseLine{
    font-size: 14px;
    background: lighten($black, 10%);
    .follow-us{vertical-align: super;}
    a{
        color: lighten($black, 30%);
        transition: all ease 0.5s;
        &:hover{
            color: $primary;
        }
    }
}
}
// Nav bar dark
.navbar-default
{
    .nav-logo-phone{ color: $white !important; text-decoration: none !important;
    &:hover, &:active, &:focus{color: $white !important;}
    }
    transition:300ms ease;
    background:transparent;
    .darknavbar{.nav-link{@include phone-up{color:$dark}  @include tablet{color:$dark;} @include tablet-up{color: $white;}}
    .dropdown-toggle{&:hover{color: $white;} &:after{content: url(../img/arrow-down-wt.svg);}}}
    #menuToggle{.fa-bars{color: $white;}}
    &.scrolled{
        background-color: #F9FBFE !important;
        border-bottom: 1px solid #e9ebed !important;
        .darknavbar{.nav-link{color: $dark;}
        .dropdown-toggle{&:after{content: url(../img/arrow-down.svg);}}
        }
        .nav-logo-phone{ color: $dark !important;}
        #menuToggle{.fa-bars{color: #555;}}
      }
      .navbar-collapse
      {
          @include phone-up {background-color: #F9FBFE !important;}
          @include tablet {background-color: #F9FBFE;}
          @include tablet-up {background-color: transparent;}
      }
}
.nav-bg-dark
{ul{li{a{ color: $white!important;}}}}
header{
    .navbar-toggler-icon
    {content: url(../img/menu-bar.png) !important;
     background-color: #f9fbfe;
    padding: 2px 4px;
    border-radius: 4px;
    }
    .navbar
    { padding: .1rem 1rem;
    @media (min-width: 992px){padding: .1rem 1.6rem;}
    .navbar-nav
    {li{ position: relative;
        a{
            color: $dark;
            padding-right: 10px !important;
            padding-left: 10px !important;
            font-size: $base-font - 3;
      
            position: relative;
            cursor: pointer;
         }
         &::after{ content: url('../img/link-dot.svg'); right: -2px;  top: 5px; position: absolute;
         @include phone-up{display: none;}
         @include desktop-down{display: none;}
         @include tablet {display: none;}
         @include tablet-up {display: inline-block;}
        }
         span{a{&:after{ content: ""; margin-left: 0px!important;}}}
        &.no-dot
        {&::after{display: none !important;} }
        }

}
}
}
.navbar
{
    &.navbar-light{
position: absolute;;
width: 100%;
z-index: 99;
margin-top: 30px;
    }
}
.navbar-brand
{
    img{
        @include phone-up{
            height: 40px;
        }
    }
}
.btn{border-radius: 100px!important; padding-left: 20px; padding-right: 20px;}
.btn-light {
    border-color: #fff !important;
    box-shadow: 0px 3px 4px rgba(0,0,0,.1);
    background-color: #ffffff !important;
    &:hover {
        background-color:$primary !important;
        border-color: $primary !important;
    }
}
.dropdown-toggle{
    &::after {
    display: inline-block;
    margin-left: 0.155em;
    vertical-align: 0.030em;
    content: url(../img/arrow-down.png);
    border:0px !important;
}
}
//// Pricing
.pricing-divider {
    padding: 1em 0 4em;
    position: relative;
    background-color: $white;
   
    }
    .princing-item {

        transition: all 150ms ease-out;
        &:hover {
          transform: scale(1.05);
          z-index: 2;
        }
        h4{font-size: $base-font - 2 !important;}
        h1{font-size: $base-font * 4 - 4!important;
            span{font-size: $base-font!important; font-weight: 200;
                &:first-child
                {font-size: $base-font + 8!important; font-weight: 400;}
            }
        }
      }
//// Testimonial 
.testimonial
{
    .carousel
    {
        background-color: transparent!important;
    }
    .carousel-indicators
    {
        position: relative !important;
        min-height: 80px;
        li{
            height: 60px;
            width: 60px;
            border-radius: 100%;
            border-top: 0px solid transparent !important;
            border-bottom: 0px solid transparent !important;
            margin-left: 6px;
            margin-right: 6px;
            opacity: .15;
            box-shadow: 0px 0px 3px #8f8f8f;
            -webkit-transition: all 0.30s ease-in-out;
            transition: all 0.30s ease-in-out;
            &.active{
                height: 80px!important;
                width: 80px!important;
                margin-top: -10px!important;
                opacity: 1!important;
                box-shadow: 0px 0px 3px #efefef;
            }
        }
    }
}
.home-testimonial{
    .carousel{background-color: transparent;}
    ol.carousel-indicators{
        
        img {
            width: 100%;
            border-radius: 2px;
        }
        .active {
            position: absolute;
            left: 38%;
            opacity: 1;
            z-index: 300;
            transform: scale(1.2);
            opacity: 1!important;
            filter: blur(0px);
            img{ box-shadow:  0 0 12px $gray-600;
              }
             }
        li {
            box-sizing: content-box;
            -ms-flex: 0 1 auto;
            border-radius: 2px;
            flex: 0 1 auto;
            width: 140px;
           height:auto;
            margin-right: 0px;
            margin-left: 0px;
            text-indent:0px; 
            cursor: pointer;
            background-color: #fff;
            background-clip: padding-box;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            opacity:1;
            transition: opacity .6s ease;
            display: block;
            filter: blur(2px) ;
            transition: all ease-in-out .25s;
            opacity: .5;
        }
        position: relative ;
        right: auto; 
          bottom:auto; 
          left:auto; 
          z-index: 15;
         display: -ms-flexbox;
         display: flex;
         -ms-flex-pack: center;
         justify-content: center;
          padding-left:auto;  
          margin-right: auto; 
          margin-left: auto; 
          list-style: none; 
          margin-top: 3%;
    }
    .carousel-inner{
        h4{color: $dark; margin-bottom: 2px;}
        h4~p{ font-size: $base-font - 4; color: $gray-500;
            span{border-bottom: 2px solid $gray-300; padding-bottom: 6px;}
        }
        p{  margin-bottom: 5px;  
            &:first-child{padding: 40px 0px;
            position: relative;
            &::before{
              	font-family: 'Font Awesome 5 Free';
  font-weight: 900; /* <-- add this */
                color: $dark;
                font-size: 24px;
                padding-right: 0.5em;
                position: absolute;
                content: url(../img/qt-top.png);
                top: -8px;left: -7px;

                }
                &::after{
                  	font-family: 'Font Awesome 5 Free';
  font-weight: 900; /* <-- add this */
                    color: $dark;
                    font-size: 24px;
                    padding-right: 0.5em;
                    position: absolute;
                    content: url(../img/qt-bottom.png) ;
                    bottom: -8px;right: 0
                    }
    }}}}
//whats-news
.whats-news{

.card {
    font-size: 1em;
    overflow: hidden;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: 0 1px 2px 0 #e5e5e5;
    .card-block {
        padding: 1em;
        border-top: 1px solid rgba(34, 36, 38, .1);
        padding-top: 8px;
    }
    .card-img-top {
        width: 100%;
        height: auto;
        border-radius: 0;
    }
    .card-title {
        font-size: $base-font + 8;
        font-weight: 700;
        line-height: $base-font + 12;
    }
    .card-text {
        clear: both;
        margin-top: .5em;
        color: rgba(0, 0, 0, .68);
    }
    .card-footer {
    padding: .75em 1em;
    color: $gray-500;
    border-top: 1px solid rgba(0, 0, 0, .02) !important;
    background-color: transparent;
    }
    .card-inverse .btn {
        border: 1px solid rgba(0, 0, 0, .05);
    }
}
}
//// Dashboard 

.dashboard-chart-box
{
background-color: $white;
box-shadow: 0px 4px 10px #eaeaea;
border-radius: 8px;
border: 1px solid #fdfdfd;
.card-title
{   font-size: $base-font;
    small{color: $gray-400;}
}
}
.table-popular-booking
{
    tr{
        td{
            vertical-align: middle;
            padding: 15px 5px;
            b{font-weight: 900!important; font-size: $base-font - 2; color: $dark; line-height: $base-font;
            small{font-weight: 900!important; color: $gray-600;}}
            
        }
        &:first-child
        td{border-top: 0px solid #000!important;}
    }
}
.table-dashboard-details
{
    tr{
        th{
            font-size: $base-font - 4;
            font-weight: 300;
            padding: 15px 5px;
        }
        td{
            vertical-align: middle;
            padding: 15px 5px;
            b{font-weight: 900!important; font-size: $base-font - 4  ; color: $dark; line-height: $base-font - 2;
            small{font-weight: 600!important; color: $gray-600; font-size: 100%!important;}}
            .btn{
                font-size: 12px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        &:first-child
        td, th{border-top: 0px solid #000!important; border-bottom: 0px solid #dee2e6 !important;}
    }
}
//// table-checkbox
.table-checkbox
{
    thead{
        background-color: #EFF5FC;
        th{border-width: 1px!important;}
    }
   tr{ th, td{
        font-size: $base-font - 2;
        line-height: 20px;
        vertical-align: middle !important;
        font-weight: 500;
        border-color: #D4E5F9 !important;
        &:last-child
        {
            box-shadow: -3px 1px 0px rgba(0, 0, 0, 0.05);
            background-color: #EFF5FC;
            text-align: center;
        }
    }
    td{
        color: $gray-600;
        font-weight: 400 !important;
        &:last-child
        {
            font-size: $base-font * 2 - 4;
            font-weight: 600!important;
            vertical-align: top;
        }
    }
    &:hover{
        td{background-color: #F9FCFF!important;
            &:last-child
            {box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.05);background-color: #F9FCFF!important;}
           }
          }
    &:last-child
    {
        border-bottom: 1px solid #D4E5F9 !important;
    }
}
}
//drop-down-outline-group
.drop-down-outline-group
{
    .btn{
    border-radius: 5px !important;
    padding: 2px 12px;
    border-color: #D4E5F9 !important;
    color: $gray-600;
    &.setings
    {
        padding: 2px 4px;
    }
    }
}
// page-item
.page-item{
    margin-left: 10px;
    .page-link
    {
        color: $gray-600;
        border: 0px solid #dee2e6;
    }
    .page-link-btn
    {
        background-color: #EFF5FC;
        color: $primary;
    }
}
/// dashboard-tab
.dashboard-page
{
   position: relative;

.dashboard-sticky-fixed
{position: relative;
.dashboard-tab
{
    background-color: #ECF3FC!important;
    position: -webkit-sticky;
	position: sticky;
    top:30px;
    z-index: 80;
}
}}

.log-out-link{
    position: absolute;
    right: 10px;
    bottom: 8px;
    text-decoration: none !important;
}
.green-btn
{
    background-color: #fff;
    box-shadow: 0px 0px 7px #ccc;
    padding: 0.38rem 1rem;
    margin-top: 2px;
    color:#569117;
    &:hover{
        color: #569117!important;
    }
    &.active{
        color: #fff!important;
        background-color: #569117!important;
    }
}

////address lt
.address-lt
{
      .card{
        padding: 1rem .8rem!important;
        border: none;
        margin-bottom:0rem;
        background-color: transparent;
        .card-title
        {
            font-size: $base-font;
            font-weight: 800;
        }
        .card-text
        {font-size: $base-font - 2;}
        &:hover{
            .card-block{ a {i{
                display:inline-block;
                font-weight:700;
                
                 }}
                }
        }
        .card-block{
            padding-left: 40px;
            position: relative;
            &:before{
              	font-family: 'Font Awesome 5 Free';
  font-weight: 900; /* <-- add this */
                text-decoration: inherit;
                color: #888888;
                font-size: 25px;
                padding-right: 0.5em;
                position: absolute;
                top: -6px;
                left: 0;
            }
         }
        .map-1{&:before{content: '';background-image: url("../img/map.svg");     left: 0;
            top: 0;
            width: 25px;
            height: 35px;
            background-repeat: no-repeat;
            background-size: 100%; }}
        .mob-1{&:before{content: '\f10b';}}
    }
}
////Agrement Subscription
.agrement-subscription
{
.agrement-scroll
{
    max-height: 450px;
    width: 100%;
	overflow-y: scroll;
    margin-bottom: 25px;
    border: 1px solid $gray-300;
    h3{font-size: $base-font; font-weight: 800;}
    p{font-size: $base-font - 2;}
}
#agrement-id
{
&::-webkit-scrollbar-track
{
    border-radius: 10px;
}
&::-webkit-scrollbar
{
    width: 10px;
}
&::-webkit-scrollbar-thumb
{
	border-radius: 10px;
    background-color: $primary;
    margin-right: 20px !important;
}
}
}
//// About us
.about-heading
{border-bottom: 1px solid $gray-300;
    p{color: $gray-500; margin-bottom: 0px; margin-top: 15px;}
}
.about-slider
{
.carousel-control-prev-icon, .carousel-control-next-icon
{
    background-color: rgba(0,0,0, .5);
}
}
.abt-contdown{
    .abt-contdown-box
    {padding: 20px 5px;text-align: center;
    img{ width: 45px; margin: 10px auto;}
    h2{margin-bottom: 0px; font-size: $base-font - 4;}
    p{color: $gray-400;margin-bottom: 0px; font-size: $base-font - 4;}
    }
}
.abt-ftr-content
{ background-color: $gray-200;border-radius: 5px;padding: 15px;
  p{margin-bottom: 2px;
  b{font-weight: 800; color: $dark; font-size: $base-font + 4;}
  &:last-child{margin-bottom: 0px; font-size: $base-font - 2;}
  .btn{margin-top: 4%;}
}}
///our-think
.our-think
{   h4, h6{font-family: $font-family-base;color: $dark;}
    h6{position: relative; padding-top: 8px;
        &::before{width: 45%;height: 4px; display: block;background-color: $gray-200;
            position: absolute;content: "";top: -8px;border-radius: 5px;
            @include phone-up{left:42.5%;text-align: center; width: 15%;}
      }}
}
/////
.cart{
    aside{
     background-color: #F8FAFB;
    }
    .cart-title{font-weight: 300;}
    .project-tab {
    margin-top: 10px;
    .nav-tabs{ .nav-link{
    border-width: 1px;
    border-radius: 0rem !important;
    color: $gray-500;
    background-color: transparent;
    border-color: transparent transparent #f3f3f3;
    font-size: 15px;
    font-weight: 400;
    &:hover{
        border-bottom: 1px solid !important;
    }
    &.active {
        color: $dark;
        border-bottom: 1px solid !important;
        font-size: 15px;
        font-weight: 400;
    }
}
}
}
}
/// Cart Product
.cart-value
{
    padding: 18px 20px 10px;
    a{text-decoration: none;}
    .close{font-weight: 400; font-size: $base-font - 4; position: relative; width: 100%; top: -4px;}
    h4{ font-size: $base-font + 4; border-bottom: 1px solid $gray-200; padding-bottom: 8px;}
    [data-toggle="collapse"]:after {
        display: inline-block;
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\f054";
            transform: rotate(270deg);
            transition: all linear 0.25s;
            float: right;
          }   
        [data-toggle="collapse"].collapsed:after {
          transform: rotate(90deg) ;
        }
        .select-cart-value
        {
            margin-top: 10px;
            .btn-select {
                position: relative;
                padding: 0;
                min-width:  150px;
                width: 80%;
                border-radius: 0!important;
                margin-bottom: 5px;
                border: 1px solid #B7D4F5;
                background-color: $white;
                font-size: 14px;
                i{ color: $primary;}
                .btn-select-value {
                    padding: 4px 10px 4px;
                    display: block;
                    position: absolute;
                    left: 0;
                    right: 34px;
                    text-align: left;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    border-top: none !important;
                    border-bottom: none !important;
                    border-left: none !important;
                }
                .btn-select-arrow {
                    float: right;
                    line-height: 18px;
                    padding: 5px 10px;
                    top: 0;
                }
                ul {
                    display: none;
                    background-color: white;
                    color: black;
                    clear: both;
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    border-top: 2px solid $gray-400!important;
                    position: absolute;
                    left: -1px;
                    right: -1px;
                    top: 28px;
                    z-index: 999;
                    border: 1px solid $gray-300;
                    li {
                        padding: 5px 6px;
                        text-align: left;
                        &:hover {
                            background-color: #f4f4f4;
                        }
                    }
                }
            }
        }
}
.cart-box
{
 tr{border-bottom: 1px solid $gray-200;
        td{padding: 15px 0px;}}
    p{margin-bottom: 5px;}
    a{img{max-width: 90px; padding: 5px;}}
}
.cart-box-text
{
width: 100%;
tr{ td{padding: 12px 0px; color: $gray-600;font-size: $base-font - 2;
    &:last-child{ max-width: 50px; text-align: right;}}
    &:last-child{
      border-top: 2px solid $gray-200;
      td{color: $dark;}
  }}}
.cart-collaps-table
{
padding: 1px 0px;
font-size: $base-font - 2;
.collaps-value{
    padding: 10px 0px;
    border-bottom: 1px solid $gray-200;
    margin-bottom: 10px;
    a{color: $gray-600; text-decoration: none;
        width: 100%;display: inline-block; cursor: pointer;
    &:after
    {font-size: 12px;margin-top: 5px;}}
}

}
//light box
.light-box {border-radius: 0px; border: 1px solid $gray-200;}
// shiping info
.shiping-info
{
    address{
        padding: 25px 20px;margin-bottom: 0px;
        p{ margin-bottom: 5px; font-size: $base-font - 2; color: $gray-600;}
    }
    .edit-add-list{ margin: 0px; padding: 0px;
        li{display: inline-block; margin-right: 10px; font-size: $base-font - 2;
        &:before {
            content: "\f111";
          	font-family: 'Font Awesome 5 Free';
  font-weight: 900; /* <-- add this */
            color: $gray-500;
            
            font-size: 8px;
            padding-right: 0.5em;
            position: relative;
            top: -2px;
            right: 4px;
        }
        &:first-child:before{ display: none;}
        }
    }
    .map
    { #contact-map { height: 220px; width: 100%;}
      .contact-map-section { position: relative; }
      .contact-section { position: absolute; margin-bottom: 0px; left: 21%; bottom: 1px; }
    }
   
}
.cart-payment
{
    .custom-radio{
        label{color: $dark;}
        p{ color: $gray-600; font-size: $base-font - 2; margin-bottom: 5px;}
    }
    .payment-card{margin-top: 20px;}
}
//Pay Tab
.pay-tab
{
.nav-tabs-wrapper{
    .nav-tabs{display: block; border: 0px; text-align: center;
    li{display: inline-block; a{font-size: $base-font - 2; color: $primary; background-color: #fff; border: 0px; margin: 5px -3px; padding: 12px 30px; text-transform: uppercase;
    &.active{background-color: $primary; color: $white;}
    }
    &:first-child{a{border-radius: 5px 0px 0px 5px;}}
    &:last-child{a{border-radius: 0px 5px 5px 0px;}}
    }
    }
}
}
// Chat Popups
.chat-popups
{position: fixed;
    z-index: 99999;
    bottom: 13%;
    right: 8px;
    img{max-width: 85%;}
}

//  owl carousel

.owl-carousel{
    .owl-item img{
        width: auto!important;
        display: block;
        margin: 0 auto;
 
 } 
 } 

 @media (min-width: 1200px){

        .row {
            margin-left: -15px!important;
            margin-right: -15px!important;
        }
 }

@media (min-width: 769px){
    .row {
        margin-left: -15px!important;
        margin-right: -15px!important;
    }
}
.min-width{
    min-width: 170px;
}

//   for select options
.dropdownCustome, .dropdown-menu{
    &.dropdown-toggle::after{
        content:none;
    }
    .dropdown-item.active, .dropdown-item:active {
      
        text-decoration: none;
        background-color: rgba(#4A90E2, 0.1)!important ;
        h4, h5{
            color: #000;
        }
    }
    padding:10px;
    small{
        font-weight:bold;
        opacity: 0.5;
        margin-bottom:5px;
    }
    .fa{
       font-size: 25px;
    }
    h5{
        font-size: 16px;
    }
    h4{
        margin-bottom: 0px;
        font-weight:bold;
        font-size: 16px;
        small{
            font-weight:bold;
            opacity: 0.5;
            font-size: 12px;
            margin-bottom:5px;
            display: inline;
            text-transform: capitalize;
            vertical-align: middle;
        }
    }
    h3{
        margin-bottom: 0px;
        font-weight:bold;
        font-size: 24px;
        small{
            font-weight:bold;
            opacity: 0.5;
            font-size: 13px;
            margin-bottom:5px;
            display: inline;
            text-transform: capitalize;
            vertical-align: middle;
        }
    }
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
.form-lable-group{
    @extend .form-group;
}


//  floating lable css

// .initial, .no-value{
//     position: relative;
// }
// .form-group  {
//     position: relative;
//     margin-bottom: 1rem;
//     @extend .initial;
//     @extend .no-value;
//   }
  
//   .form-group  > input,
//   .form-group  > label {
//     // padding: var(--input-padding-y) var(--input-padding-x);
//   }
  
//   .form-group  > label {
     
//     position: absolute;
//     top: 15px;
//     left: 15px;
//     display: block;
//     width: 100%;
//     pointer-events: none;
//     margin-bottom: 0; /* Override default `<label>` margin */
//     line-height: 1.5;
//     color: #495057;
//     border: 1px solid transparent;
//     border-radius: .25rem;
//     transition: all .1s ease-in-out;
//     &.floating{
//        top: 7px;
//     font-size: 10px;
//     opacity: 0.5;
//     color: #000;
//     left: 13px;
//     padding-left: 4px;
//     font-weight: bold;
//       }
//   }
  
//   .form-group  input::-webkit-input-placeholder, .form-group  textarea::-webkit-input-placeholder {
//     color: transparent;
//   }
  
//   .form-group  input:-ms-input-placeholder, .form-group  textarea:-ms-input-placeholder {
//     color: transparent;
//   }
  
//   .form-group  input::-ms-input-placeholder, .form-group  textarea::-ms-input-placeholder {
//     color: transparent;
//   }
  
//   .form-group  input::-moz-placeholder, .form-group  textarea::-moz-input-placeholder {
//     color: transparent;
//   }
  
//   .form-group  input::placeholder , .form-group  textarea::placeholder{
//     color: transparent;
//   }
  
//   .form-group  input:not(:placeholder-shown) {
//     // padding-top: calc(var(--input-padding-y) + var(--input-padding-y));
//     padding-bottom: calc(var(--input-padding-y) / 3);
//   }
//   .form-group  textarea:not(:placeholder-shown) {
//     // padding-top: calc(var(--input-padding-y) + var(--input-padding-y));
//     padding-bottom: calc(var(--input-padding-y) / 3);
//     padding-top: 20px;
//   }

//   .form-group  input:focus:-ms-input-placeholder {
//     opacity: 0;
//   }
  
//   .form-group  input:invalid:not(:focus):-ms-input-placeholder {
//     color:transparent;
//   }


  



//   payment page main tab design

.customeTabDesign{
   .nav-link{
       padding: 15px 40px;
       background-color:$white;
        font-size: $base-font ;
        font-weight: 500;
        @extend .text-uppercase;
       &.active{
           &:first-child{
                border-radius: 5px 0 0 5px;
           }
           &:last-child{
            border-radius: 0px 5px 5px 0px;
       }
          
       }
   }
}
.customeTabDesignContent{
    .card{
        border: none;
        border-radius: 0;
        padding: 4rem 2rem 2rem 2rem;
        &.active{
            outline: 2px solid #4A90E2!important;
            z-index: 2;
            .btn{
                background-color: $primary;
                border: none;
                color: $white;
                &:hover{
                    background-color: darken($primary, 10%);
                }
            }
        }

    }
    .shadow{
       
        box-shadow: 0px 0px 30px rgba($black, .2)!important;
      
    }
    h1{
        font-size: $base-font * 4;

        small{
            &:first-child{
                font-size: $base-font + 10;
                color: $black;
                font-weight: normal;
           }
            font-size: $base-font;
            color: $gray-600;
        }
        font-weight: bold;
        color:#000;

    }
    ul{
        list-style: none;
    li{
        position: relative;
        padding-left: 30px;
        &:before{
            content: "";
            background-image: url('../img/check-mark-button.png');
            left: 0;
            top: 3px;
            position: absolute;
            width: 16px;
            height: 16px;
        }
    }
        margin: 0;
        padding: 0;
        p{
            margin-bottom: 5px;
        }
    }
    .btn{
        max-width: 80%;
        margin:  0 auto;
    }
}
//Cross Icon remove from IE
input[type=text]::-ms-clear{display: none;}
input[type=number]::-ms-clear{display: none;}
input[type=tel]::-ms-clear{display: none;}
.navbar-default.scrolled
{
    background-color: #F9FBFE !important;
    border-bottom: 1px solid #e9ebed !important;
    border-top: 1px solid #e1e1e1 !important;

}
textarea{ 
    display: block !important;
    overflow: hidden;
    resize: vertical;
} 
.customeButton{
    border-radius: 0 4px 4px 0!important;
    padding: 5px 20px;
    font-weight: bold;
    color: #4A90E2;
    &:hover{
        color: #fff;
    }
  }
  .customeInput {
    padding: 5px 20px !important;
    height: 40px!important;
    border: none;
}
.ctaBlock{
    font-size: 13px;
}
// .infoBacking {
//     &.responsive{
//         position: relative;
//         width: 100%;
//         h4{
//             font-size: 18px;
//             color: #000;
//         }

//     }
//     padding: 10px!important;
//     position: fixed;
//     top: 0;
//     width: 100%;
//     font-size: 12px;
//     z-index: 30;
  
//   }
//   .fixed-top {
//     @include media-breakpoint-down(sm) {
//         top: 0px!important;
//     }
  
//     top: 80px!important;
  
//   }

.Page.navigation{
    position: relative;
    display: inline-block;
    overflow: hidden;
    .page-numbers{
        list-style: none;
        margin: 0;
        padding: 0;
        li{
            display: inline-block;
            border: 1px solid #ddd;
            margin-right: -4px;
         
            border-right: none;
            &:first-child{
                border-radius: 4px 0 0 4px;
                
            }
            &:last-child{
                border-radius: 0px 4px 4px 0;
                border-right: 1px solid #ddd;
                margin-right: 0;
            }
            a{
                padding: 5px 10px;
                display: block;
                cursor: pointer;
            }
            span{
                display: block;
                color:#999;
                padding: 5px 10px;
            }
        }
    }

}
.topsocial{
  padding-top: 8px;
  margin-left: 8px;
  @media (max-width: 767px)
    {margin-left: 20%; padding-top: 5px;}
  a i{
    font-size: 18px;
    padding-right: 3px;
  }
}
.top-bg-15{
  background-color: #4A90E2;
  border-bottom: 1px solid #4A90E2;
  z-index: 9;
  position: fixed;
  padding: 5px 0px;
  width: 100%;
  h4{
    font-size: 16px;
    margin-bottom: 0px;
    font-weight: 400;
    span{
      &:first-child{display: inline-block;
        color: #ffffff;
        margin-top: 4px;
        @media (max-width: 767px)
        {
          margin-top: 3px;
          display: block;
          text-align: center;
        }
      }
      &:last-child{
        float: right;
        @media (max-width: 767px)
        {
          float: unset;
          display: block;
          text-align: center;
          margin-top: 3px;
        }
        a{
          padding: 0px 14px !important;
          min-width: 120px !important;
          background-color: #ffffff;
          color:#4A90E2;
          font-weight: 400;
          &:hover{
            color: #297cdd;
          }
        }
      }
    }
  }
}

.fixed-top.header
{
  margin-top: 35px;
  @media (max-width: 767px)
    {
      margin-top: 60px;
    }
}
.home-page
{
.fixed-top.header
{
  .navbar-brand.logo1
  {
    display: none;
  }
}
}
.inner-page
{
.fixed-top.header
{
//   .navbar-brand.logo2
//   {
//     display: none;
//   }
&.scrolled{
  .navbar-brand.logo2
  {
    display: block !important;
  }
  .navbar-brand.logo1
  {
    display: none;
  }
}
}
}
.nav-mail{
  vertical-align: middle;
  .fa-envelope{
   font-size: 16px;
    padding-left: 8px;
    padding-right: 3px;
    color: #4A90E2;
  }
}
.inner-page{
  .fixed-top{
  .nav-mail{
    .fa-envelope{
      color: #ffffff;
    }
  }
  &.scrolled{
    .nav-mail{
      .fa-envelope{
        color: #4A90E2;
      }
    }
  }
}
}
.aboutImg{
 @media (max-width:767px)
 {
   width: 100%;
   max-width: 100% !important;
   float: unset !important;
   margin-bottom: 15px !important;
    margin-left: auto !important;
    display: block;
    margin-right: auto !important;
 }
}

.sectionTitle{
    font-size: 40px;
    margin-bottom: 30px;
    @include phone-up{
        font-size: 20px;
        margin-bottom: 0px;
    }
}



//  for mobile menu section

.custom-dropdown{

    .dropdown-menu{
        top: 50px!important;
        &::before{
            content: "";
            width: 40px;
            height: 40px;
            background-color:#4A90E2 ;
            position: absolute;
            top: -10px;
            right: 40px;
            transform: rotate(45deg);
            @media (max-width:767px)
            {
                display: none;
            }
        }
        border-radius: 10px;
        box-shadow: 0 0 3px #ddd;
        border: none;;
    padding: 20px  ;
    @media (min-width:767px)
    {
       
        width: 600px;
        left: auto;
        top: 20px;
        right: 0px!important;
        transform: translateX(40px);
    }
    top: 20px;
        background-color: #4A90E2;
        a.dropdown-item{
            border-radius: 50px;
            color: #fff;
            &:hover, &:focus, &:active{
                background: rgba(0,0,0,0.3);
                color: #fff;
                font-weight: bold;
            }
        }
       
    }
}

.extraPadding{
    @media screen and (max-width:600px) {
        padding:20px 10px; 
    }

    padding-top: 100px;
    padding-bottom: 100px;
}


//  for home page

.serviceBlockSection{
    display:flex;
    width: 100%;
    @media screen and (max-width:600px) {
        flex-direction: column;
        
    }
    gap: 0.5rem;



        .flexCardBlock{
            position: relative;
            overflow: hidden;
            z-index: 0;
            cursor: pointer;
            width: 100%;
            

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: end;
            padding: 40px;
            min-height: 400px;
            background-position: center center;
            background-size:cover;
            .bg-ImgTrans{
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                object-fit: cover;
                z-index: -1;
            }
           
            &:hover{
               
          
                    .bg-ImgTrans{
                        transform: scale(1.2);
                    }
            
               .flexCardComponent{
                margin-bottom: 0px;
               }
            }
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 0;
                background: rgb(0,0,0);
                background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 49%);
            }
            .flexCardComponent{
                position: relative;
                margin-bottom: -20px;
                z-index: 2;
                .icon img{
                    max-width: 80px;
                }
                h5{
                    color: #fff;
                    font-size: 18px;
                    text-transform: capitalize;
                }
            }

        }

}

.circleWrap{
    position: relative;

    .circle-2{
        border: 2px solid #ddd;
        width: 500px;
        height: 500px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .circle-1{
        border: 2px solid #ddd;
        width: 350px;
        height: 350px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media screen and (min-width:1025px) {
    .first-Child{
        position: absolute;
        left: 120px;
    }
    .second-Child{
        position: absolute;
        left: 20px;
        top: 200px;}

        .third-Child{
            position: absolute;
            left: 180px;
            top: 400px;
        }

        .fourth-Child{
            position: absolute;
            right: 120px;
        }
        .fifth-Child{
            right: 20px;
            position: absolute;
            top: 200px;}
            
            .sixth-Child{
                position: absolute;
                right: 120px;
                top: 400px;
            }
        }



        @media screen and  (min-width:600px) and (max-width:1024px) {
            .first-Child{
                position: absolute;
                left: 70px;
            }
            .second-Child{
                position: absolute;
                left: -20px;
                top: 200px;}
        
                .third-Child{
                    position: absolute;
                    left: 70px;
                    top: 400px;
                }
        
                .fourth-Child{
                    position: absolute;
                    right: 90px;
                }
                .fifth-Child{
                    right: -20px;
                    position: absolute;
                    top: 200px;}
                    
                    .sixth-Child{
                        position: absolute;
                        right: 90px;
                        top: 400px;
                    }
                }
}

.AniamteDesign{
    align-items: center;
    @media screen and  (min-width:600px) and (max-width:1024px) {
        flex-direction: column;
        gap: 0rem;
    }

    color: #2B3344;
    &:hover{
        color: $primary;
    }
    gap: 1rem;

    span{

        @media screen and  (min-width:600px) and (max-width:1024px) {
         order: 1;
         text-align: center!important;
        }
        font-size: 18px;
        font-weight: bold;
        max-width: 200px;


    }


.imgCont{
    padding: 10px;
    width: 100px;
    height: 100px;
    background: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    img{
        width: 100%;
    }
}
}