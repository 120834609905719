

//  variable overrides
$primary : #4A90E2;
$primary-sdw: #EFF6FF;
$body-bg: #fff;
$body-color: #555555;
$shadow-border:#F0F6FF;
$black: #000000;
$dark-gray: #909090;
$base-font :16px;
$theme-colors: (
  "primary": #4A90E2,
  "danger": #ff5257,
  "info": #4A90E2,
  "warning": #FFBE68,
  "success": #7ED321,
  "light": #F9FBFE,
  "dark": #000000,
  "gray":#555555,
  "secondary":#2B3344,
  "dark-gray":#1F1F1F
);


$font-family-base:'Roboto', sans-serif;
// $family-Helvetica:'Helvetica Neue';
$border-radius:               0.2rem !default;
//  for deopdown
$dropdown-border-radius: 0px;

$dropdown-spacer:                  0!default;

$input-btn-padding-y:         .8rem !default;
$input-btn-padding-x:         1rem !default;
$input-border-width:           1px !default;

$btn-font-weight: 300;
// $custom-select-border-radius:       50px !default;
//  badge font styling
$badge-font-weight:                 300 !default;

$box-shadow-sm:               0 .125rem .25rem $shadow-border !default;
$box-shadow:                  0 .5rem 1rem $shadow-border !default;
$box-shadow-lg:               0 1rem 3rem $shadow-border !default;

$card-border-color:                 $shadow-border !default;

// navbar toggle button
$navbar-toggler-padding-y:          .15rem !default;
$navbar-toggler-padding-x:          .15rem !default;




// modal backdrop
$modal-backdrop-bg:                 $primary !default;
$modal-content-border-radius:       0px!default;
$modal-content-border-color:        rgba($primary, .2) !default;

// Jumbotron

$jumbotron-padding:                 2rem !default;
$jumbotron-color:                   $black!default;


//  theme element variables

$titleSection : 40px;
$baseFontSize :15px;

/// Break point
@mixin phone{
  @media (max-width: 359px) { @content; }
}
@mixin phone-up{
  @media (max-width: 575px) { @content; }
}
@mixin tablet-down{
  @media (max-width: 767px) { @content; }
}
@mixin tablet{
  @media (min-width: 768px) { @content; }
}
@mixin tablet-up{
  @media (min-width: 992px) { @content; }
}

@mixin desktop-down{
  @media (max-width: 960px) { @content; }
}
@mixin desktop{
  @media (min-width: 1200px) { @content; }
}
@mixin desktop-up{
  @media (min-width: 1800px) { @content; }
}



 