
.breadCumBg{
    background-position: 0% 10px;
    background-image: url(assets/contact-bg.png) !important;
    &:before{
        background-color: rgba(0, 0, 0, 0.7) !important;
    }
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #ffffff;
    background-color: #4A90E2;
}

.form-text.text-muted{
    color: red !important;
}
.Invalid {
    border: 1px solid #ced4da !important;
}
#map-9cd199b9cc5410cd3b1ad21cab2e54d3 > iframe + iframe {
    display: none !important;
}
.form-label-group{
    margin-bottom: 10px;
}