.services-img.jumbotron-fluid{
    background-position: 0% 0px;
    background-image: url(assets/register-bg.png) !important;
    &:before{
        background-color: rgba(0, 0, 0, 0.7) !important;
    }
}
.login-rt{
    padding: 50px;
    @media screen and (max-width:640px){
        padding: 30px;
    }
}
.input-error {
    color: #FA3B18;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 0px;
    margin-top: 8px;
    position: absolute;
    right: 20px;
    left: 20px;
}
.forgetPasswordForm {
    .form-group {
        margin-bottom: 2rem;
        .input-error{
            left: 60px;
        }
    }
}

.input-errors{
    color: #FA3B18;
    font-size: 12px !important;
    line-height: 14px;
    margin-bottom: 0px;
    margin-top: 4px;
    position: relative;
    
}
.notifications-wrapper{
    .notifications-tr
    {
      @media(max-width:767px){
        left: unset !important;
        right: 5px !important;
        top: 5px !important;
    }
  }
  }

