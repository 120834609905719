.services-img.jumbotron-fluid{
    background-position: 0% 0px;
    background-image: url(assets/aboutus2.jpg) !important;
    &:before{
        background-color: rgba(0, 0, 0, 0.7) !important;
    }

    .srv-validation-message{
        color: red;
        font-size: 12px;
        margin: 0px;
        padding-left: 20px;
        font-family: "Roboto", sans-serif;
        font-weight: 300;
    }
}


.loading {
    color: #4A90E2;
    text-align: center;
    display: block;
    font-size: 20px;
    width: 100%;
}