.reqDemoBg {
  background-image: url('../../assets/img/home-banner.png');
  background-repeat: no-repeat !important;
  background-attachment: initial !important;
  background-origin: initial !important;
  background-clip: initial !important;
  background-color: initial !important;
}

.breadCumBg
.reqDemoBg:before{
  background-color:rgba(0, 0, 0, 0.5) !important;
}

.bookButton{
    color: #fff !important;
    background-color: #4A90E2 !important;
    border-color: #4A90E2 !important;
}