// Custom.scss
// Option B: Include parts of Bootstrap

//  font family @import
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&display=swap');

//  common classes for bootstrap input



//  final theme styles
@import "variables.scss";
@import 'bootstrap/scss/bootstrap.scss';
@import "custome.scss";

//  extra plugin styles


@import "./../css/animate.css";
@import "./../css/mg-space.css";
@import "./../css/mg-space-grid-min.css";