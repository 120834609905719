.bgImage{
    background: url('./../../assets/img/home-banner.png') no-repeat center center;

    
}
.jumbotron-fluid{
    height: 100%;;
}  

.service_title{
    b{
        font-size: 14px;
    }
}
.videoImproveCSS{
    background: #333;
    border-radius: 0.3rem;
    box-shadow: 1px solid #333;
}
#modalCopyComp {
    box-shadow: none !important;
}
.videoWrap{
    position: relative;
    &:before{
        content: '';
        height: 100px;
        background: rgb(0,0,0);
        background: linear-gradient(-180deg, rgba(0,0,0,0.7469581582633054) 0%, rgba(0,0,0,0.35) 49%, rgba(0,0,0,0) 94%);;
        filter: blur(20px);
        transform: scale(3);
        width: 100%;
        position: fixed;
        left: 0;
        top: 0;
    }

    @media screen and (min-width:1025px) {
        height: 100vh;
    }
    min-height: 220px;
    width: 100%;

object-fit: fill;
    z-index: -8;


.fsVideo{
    width: 100vw;
    @media screen and (min-width:1025px) {
        position: fixed;
        height: 100vh;
    }
  
    z-index: -2;
    aspect-ratio: 16 9;
    object-fit: cover;

   
}
}


.navbar-collapse {
    @media screen and (max-width:600px) {
        width: 100%;
        position: fixed;
        left: 0;
        top: 50px;
        margin: 0 !important;
        box-shadow: 0 0 10px #ddd;
        z-index: 100;
    } 
}

